import { Link } from '@remix-run/react';

import type { HTMLAttributes } from 'react';

import { cn } from '~/utils/css';
import { routes } from '~/utils/routes';

export function CTA(
  props: Exclude<HTMLAttributes<HTMLDivElement>, 'children'>
) {
  const { className, ...rest } = props;

  return (
    <div
      className={cn('relative bg-white dark:bg-zinc-950', className)}
      {...rest}>
      <div className='mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8'>
        <div className='overflow-hidden rounded-lg bg-brand-700 shadow-xl dark:bg-brand-900 sm:rounded-2xl lg:grid lg:grid-cols-2 lg:gap-4'>
          <div className='px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20'>
            <div className='lg:self-center'>
              <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
                <span className='block'>Ready to get into shape?</span>
              </h2>

              <p className='mt-4 text-lg leading-6 text-brand-200'>
                Let&apos;s take the first step today.
              </p>

              <div className='mt-8 flex items-center justify-start gap-x-6'>
                <Link
                  to={routes.orderNow.index}
                  className='rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-brand-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'>
                  Order now
                </Link>

                <Link
                  to={routes.orderNow.checkDelivery}
                  className='text-sm font-semibold leading-6 text-white'>
                  Check my postcode <span aria-hidden='true'>→</span>
                </Link>
              </div>
            </div>
          </div>

          <div className='aspect-h-3 aspect-w-5 -mt-8 md:aspect-h-2 md:aspect-w-3'>
            <img
              className='translate-x-6 translate-y-6 transform rounded-md object-cover object-center sm:translate-x-16 sm:rounded-xl lg:translate-y-20'
              src='/images/cta.jpg'
              alt='Baked Potatoes, rice, peas and carrot on a plate with two slices of lemon'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
